
$yellow: #f1c40f;
$lime: #76c900;
$navy: #0a4069;
$cyan: #57caf4;
$red: #ff0c78;
$betcha-color: #ff0c78;
$white: #fefefe;
$gray: #444;
$lightGray: lighten($gray, 30);

$transitionDuration: 0.3s;
$transition: all $transitionDuration ease-in-out;

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  line-height: 1em;
  color: $gray;
}

section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2em;
  padding: 0;
  &:last-of-type {
    margin-bottom: 0;
  }
  article {
    margin-left: 20px;
    width: 20em;
    margin-bottom: 2em;
    p,
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
p {
  line-height: 1.5em;

  max-width: 20em;
  margin: 1.5em auto 2em;
  padding-bottom: 1.5em;
  span {
    display: block;
  }
}
.container {
  font-size: 5px;
  
  // margin-left: -110px;
}

/*
*
*
START // CHART'S RULES
 -> "if you're picking code, don't forget the variables :)"
*/

.chart {
  font-size: 1em;
  
  perspective: 1000px;
  perspective-origin: 50% 50%;
  backface-visibility: visible;
}

$faceColor: rgba($white, 0);
$growColor: rgba($red, 1);

.bar {
  font-size: 1em;

  position: relative;

  height: 10em;

  transition: $transition;
  transform: rotateX(60deg) rotateY(0deg);

  transform-style: preserve-3d;

  .face {
    font-size: 0.2em;
    position: relative;
    width: 127.8px;
    height: 2.2em;
    background-color: $faceColor;

    &.side-a,
    &.side-b {
      width: 2em;
    }
  }
  .side-a {
    transform: rotateX(90deg) rotateY(-90deg) translateX(2em) translateY(1em)
      translateZ(1em);
  }
  .side-b {
    transform: rotateX(90deg) rotateY(-90deg) translateX(4em) translateY(1em)
      translateZ(-1em);
    position: absolute;
    right: 0;
  }
  .side-0 {
    transform: rotateX(90deg) rotateY(0) translateX(0) translateY(1em)
      translateZ(-1em);
  }
  .side-1 {
    transform: rotateX(90deg) rotateY(0) translateX(0) translateY(1em)
      translateZ(3em);
  }
  .top {
    transform: rotateX(0deg) rotateY(0) translateX(0em) translateY(4em)
      translateZ(2em);
  }
  .floor {
    box-shadow: 0 0.6em 2em rgba(0, 0, 0, 1),
      0.8em -0.5em 10em rgba(0, 0, 0, 0.3), 0em -2em 10em $white;
  }
}

.growing-bar {
  transition: $transition;
  background-color: $growColor;
  width: 100%;
  height: 2.2em;
}

@mixin drawSkin($color, $name) {
  .bar.#{$name} {
    .side-a,
        // &.bar-100 .side-b,
        .growing-bar {
      background-color: rgba($color, 0.6);
    }
    .side-0 .growing-bar {
      box-shadow: -0.5em -1.5em 4em $color;
    }
    .floor .growing-bar {
      box-shadow: 0em 0em 2em $color;
    }
  }
}

@mixin drawFaces($color, $name) {
  .chart .bar.#{$name} .face {
    background-color: rgba($color, 0.2);
  }
}

@include drawSkin(rgba($yellow, 0.8), "yellow");
@include drawSkin(rgba($red, 0.8), "red");
@include drawSkin($cyan, "cyan");
@include drawSkin(rgba($navy, 0.8), "navy");
@include drawSkin($lime, "lime");
@include drawSkin($white, "white");
@include drawSkin($gray, "gray");

@include drawFaces(rgba($yellow, 0.6), "yellow-face");
@include drawFaces($lime, "lime-face");
@include drawFaces(rgba($red, 0.6), "red-face");
@include drawFaces(rgba($navy, 0.6), "navy-face");
@include drawFaces($cyan, "cyan-face");
@include drawFaces($gray, "gray-face");
@include drawFaces($lightGray, "lightGray-face");

@for $i from 0 to 101 {
  .bar-#{$i} {
    .growing-bar {
      width: percentage($i/100);
    }
  }
}

/*
END // CHART'S RULES
*
*
*/

.chart.grid {
  display: flex;
  flex-direction: row;
  .exercise {
    flex: 0 0 100%;
    display: flex;
    .bar {
      flex: 1;
      margin: 0 0.5em;
      &:nth-child(2) {
        z-index: 8;
        flex: 1 0 40%;
      }
      &:first-child {
        z-index: 10;
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.actions {
  display: flex;

  justify-content: center;

  margin-bottom: 0;
  padding-bottom: 2em;
  border-bottom: 1px dotted rgba($gray, 0.4);
}
label {
  box-sizing: border-box;
  padding: 1em;
  margin: 0 0.2em;

  cursor: pointer;
  transition: all 0.15s ease-in-out;

  color: $navy;
  border: 1px solid rgba($white, 0.6);
  border-radius: 0;

  flex: 1;
  &:first-child {
    margin-left: 0;

    border-radius: 0.2em 0 0 0.2em;
  }
  &:last-child {
    margin-right: 0;

    border-radius: 0 0.2em 0.2em 0;
  }
}

input[id="exercise-1"]:checked ~ .actions label[for="exercise-1"],
input[id="exercise-2"]:checked ~ .actions label[for="exercise-2"],
input[id="exercise-3"]:checked ~ .actions label[for="exercise-3"],
input[id="exercise-4"]:checked ~ .actions label[for="exercise-4"],
input[id="pos-0"]:checked ~ .actions label[for="pos-0"],
input[id="pos-1"]:checked ~ .actions label[for="pos-1"],
input[id="pos-2"]:checked ~ .actions label[for="pos-2"],
input[id="pos-3"]:checked ~ .actions label[for="pos-3"],
input[id="red"]:checked ~ .actions label[for="red"],
input[id="cyan"]:checked ~ .actions label[for="cyan"],
input[id="lime"]:checked ~ .actions label[for="lime"] {
  color: $lime;
  border: 1px solid darken($navy, 15);
  background-color: $navy;
}

input[id="red"]:checked ~ .chart {
  @extend .bar.red;
}
input[id="cyan"]:checked ~ .chart {
  @extend .bar.cyan;
}
input[id="lime"]:checked ~ .chart {
  @extend .bar.lime;
}

input[id="pos-0"]:checked ~ .chart {
}
input[id="pos-1"]:checked ~ .chart {
  @extend .bar-90;
}
input[id="pos-2"]:checked ~ .chart {
  @extend .bar-75;
}
input[id="pos-3"]:checked ~ .chart {
  @extend .bar-100;
}
input[id="exercise-2"]:checked ~ .chart.grid {
  .exercise .bar {
    &:nth-child(1) {
      flex: 1 0 0%;
      @extend .bar-20;
    }
    &:nth-child(2) {
      flex: 1;
      @extend .bar-50;
    }
    &:nth-child(3) {
      flex: 1 0 30%;
      @extend .bar-80;
    }
  }
}
input[id="exercise-3"]:checked ~ .chart.grid {
  .exercise .bar {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      flex: 1;
      @extend .bar-70;
    }
  }
}
input[id="exercise-4"]:checked ~ .chart.grid {
  .exercise .bar {
    &:nth-child(1),
    &:nth-child(2) {
      flex: 1 0 30%;
      @extend .bar-50;
    }
    &:nth-child(3) {
      flex: 1;
      @extend .bar-100;
    }
  }
}
