.glow {
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }
  
  @keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #d91c08, 0 0 40px #d91c08, 0 0 50px #d91c08, 0 0 60px #d91c08, 0 0 70px #d91c08;
    }
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #d91c08, 0 0 40px #d91c08, 0 0 50px #d91c08, 0 0 60px #d91c08, 0 0 70px #d91c08, 0 0 80px #d91c08;
    }
  }

/* 
  @keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #FF0D78, 0 0 40px #FF0D78, 0 0 50px #FF0D78, 0 0 60px #FF0D78, 0 0 70px #FF0D78;
    }
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #FF0D78, 0 0 40px #FF0D78, 0 0 50px #FF0D78, 0 0 60px #FF0D78, 0 0 70px #FF0D78, 0 0 80px #FF0D78;
    }
  } */